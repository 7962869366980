import '../../App.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import UserApiClient from '../../ApiClients';
import { BaseApiUrl } from '../../Configs/enviroment';
import Swal from 'sweetalert2';
import Avatar from 'react-avatar';
import { Modal, Button } from 'react-bootstrap';
import { UserContext } from '../../TabContextProvider';


function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const emptyModelData = {
    name: '',
    instruction: '',
    model: '',
    temperature: 0.5,
}

const formFields = {
    name: '',
    size: '',
    type: 'text',
    status: true
}

function Settings({ data }) {

    const { chatbotData, chatbotAppearance, User, setChatbotData, setChatbotAppearance } = data;
    const { Team } = useContext(UserContext);
    const [appearanceData, setAppearanceData] = useState({ ...chatbotAppearance });
    const [botData, setBotData] = useState({ ...chatbotData });
    const [chatbotBgImage, setChatbotBgImage] = useState(null);
    const [chatbotImage, setChatbotImage] = useState(null);
    const [chatbotLogo, setChatbotLogo] = useState(null);
    const chatbotLogoRef = useRef();
    const chatbotImageRef = useRef();
    const chatbotBgImageRef = useRef();
    const chatbotBgViewRef = useRef();
    const chatbotLogoViewRef = useRef();
    const chatbotImageViewRef = useRef();
    const loadFormHeadingRef = useRef();
    const [value, setValue] = useState(0);
    const [modelList, setModelList] = useState([]);
    const [modelBox, setModelBox] = useState({ ...emptyModelData, chatbot: chatbotData.id });
    const [showLoadForm, setShowLoadForm] = useState();
    const [loadFormHeading, setLoadFormHeading] = useState('');
    const [loadForms, setLoadForms] = useState([]);
    const [sampleFormData, setSampleFormData] = useState(formFields);
    let isFetching = false;


    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    useEffect(() => {
        if (chatbotAppearance?.id) {
            // setShowLoadForm(chatbotAppearance?.show_load_form);
            setLoadFormHeading(chatbotAppearance.load_form[0].text);
            setLoadForms(chatbotAppearance.load_form[0].data);
        }
    }, [chatbotAppearance])

    const handleRemoveField = (name) => {
        setLoadForms(loadForms.filter(form => form.name !== name));
    };

    const handleLoadFormChange = (e) => {
        const { name, value } = e.target;
        setSampleFormData({ ...sampleFormData, [name]: name === 'size' ? parseInt(value) : value });
    };

    const handleAddSubmit = (e) => {
        e.preventDefault();
        const newLoadForms = [...loadForms, sampleFormData];
        setLoadForms(newLoadForms);

        // Reset form data
        setSampleFormData(formFields);
        handleClose();
    };

    const handleListSubmit = async (event) => {

        await UserApiClient.post(`bot/load-form/add/${chatbotAppearance.id}/`, {
            show_load_form: true,
            text: loadFormHeading,
            data: loadForms,
        }, true).then(async (res) => {
            Swal.fire(res.data.message, '', 'success', 3000)
        });
    };

    const resetMainText = () => {
        const text = "Let us know how to contact you";
        setLoadFormHeading(text);
        loadFormHeadingRef.current.value = text;
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    const valuetext = (value) => {
        return `${value}`;
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;

        const Files = Array.from(e.target.files);
        // Check if files are selected
        if (files.length > 0) {
            const reader = new FileReader();

            // Read the selected file
            reader.readAsDataURL(files[0]);

            // When the file is loaded
            reader.onload = () => {
                const imageDataUrl = reader.result;
                // Set the image source to the reader result
                if (name == "chatbot_logo") {
                    setChatbotLogo(Files);
                    // chatbotLogoBottomViewRef.current.src = imageDataUrl;
                    chatbotLogoViewRef.current.src = imageDataUrl;
                    chatbotLogoRef.current.src = imageDataUrl;
                } else if (name == "chatbot_image") {
                    setChatbotImage(Files);
                    chatbotImageViewRef.current.src = imageDataUrl;
                    chatbotImageRef.current.src = imageDataUrl;
                } else if (name == "chatbot_background_image") {
                    setChatbotBgImage(Files);
                    chatbotBgImageRef.current.src = imageDataUrl;
                    chatbotBgViewRef.current.style.backgroundImage = `url('${imageDataUrl}')`;
                }
            };
        }
    };

    const handleAppearanceChange = (e) => {
        const { name, value } = e.target;
        setAppearanceData({
            ...appearanceData,
            [name]: value,
        });
    };

    const handleAppearanceSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        Object.keys(appearanceData).forEach((key) => {
            formDataToSend.append(key, appearanceData[key]);
        });
        if (chatbotImage && chatbotImage[0]) {
            formDataToSend.append("chatbot_image", chatbotImage[0]);
        } else {
            formDataToSend.delete('chatbot_image');
        }
        if (chatbotLogo && chatbotLogo[0]) {
            formDataToSend.append("chatbot_logo", chatbotLogo[0]);
        } else {
            formDataToSend.delete('chatbot_logo');
        }
        if (chatbotBgImage && chatbotBgImage[0]) {
            formDataToSend.append("chatbot_background_image", chatbotBgImage[0]);
        } else {
            formDataToSend.delete('chatbot_background_image');
        }
        formDataToSend.delete('load_form');
        await UserApiClient.put(`bot/chatbot/appearances/${chatbotData.id}/`, formDataToSend, true).then(async res => {
            if (res.success) {
                setAppearanceData(res.data.data);
                setChatbotAppearance({ ...chatbotAppearance, ...res.data.data });
                Swal.fire(res.data.message, '', 'success', 3000)
            }
        })
    };

    const handleSecurityChange = (e) => {
        const { name, value } = e.target;
        setBotData({
            ...botData,
            [name]: value,
        });
    };

    const handleSecurityFormSubmit = async (e) => {
        e.preventDefault();

        delete botData.icon_url;
        botData["chatbot_appearance"] = chatbotAppearance.id
        await UserApiClient.put(`bot/chatbot/update/${chatbotData.id}/`, botData, true).then(async res => {
            if (res.success) {
                setBotData({ ...botData, ...res.data });
                setChatbotData({ ...chatbotData, ...res.data });
                Swal.fire("Chatbot Updated.", '', 'success', 3000)
            }
        })
    }

    const handleAddModel = async (e, item) => {
        e.preventDefault();
        setModelBox(item || { ...emptyModelData, chatbot: chatbotData.id });
    }

    const handleAddModelFromSubmit = async (e) => {
        e.preventDefault();
        await UserApiClient.post(`bot/chatbot/model/${chatbotData.id}/`, { ...modelBox }, true).then(async res => {
            if (res.success) {
                await fetchModelProfilesData()
                Swal.fire(res.data.message, '', 'success', 3000);
            }
        })
    }
    const handleActiveModel = async (e, item) => {
        await Swal.fire({
            title: "Are you sure?",
            text: `You want to active ${item.name}!`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Active!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.put(`bot/chatbot/model/activate/${item.id}/`, { 'is_active': true }, true).then(async res => {
                    // console.log(res.data);
                    if (res.success) {
                        await fetchModelProfilesData();
                        Swal.fire(res.data.message, '', 'success', 3000);
                    }
                })
            }
        });
    }

    const handleModelProfileChange = (e) => {
        const { name, value } = e.target;
        setModelBox({
            ...modelBox,
            [name]: value
        })
    }

    async function fetchModelProfilesData() {
        isFetching = true;
        await UserApiClient.get(`bot/chatbot/model/${chatbotData.id}/`, {}, true).then(async res => {
            if (res.success) {
                setModelList(res.data);
                setModelBox({ ...emptyModelData, chatbot: chatbotData.id })
                isFetching = false;
            }
        })
    }

    async function handleDeleteModelProfile(id) {
        await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.delete(`bot/chatbot/model/delete/${id}/`, {}, true).then(async res => {
                    if (res.success) {
                        await fetchModelProfilesData();
                        Swal.fire(res.data.message, '', 'success', 3000)
                    }
                })
            }
        });
    }

    useEffect(() => {
        if (chatbotData.id && !isFetching) {
            fetchModelProfilesData();
        }
    }, [chatbotData])

    useEffect(() => {
        setAppearanceData({ ...chatbotAppearance })
        setBotData({ ...chatbotData })
    }, [chatbotAppearance, chatbotData])

    return (
        <>
            <div className='row'>
                <div className='col-sm-12 col-md-3 col-lg-3 col-xl-3'>
                    <div className='containers p-0'>
                        <div className='p-2'>
                            <Box
                                sx={{}}
                            >
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleTabChange}
                                    aria-label="Vertical tabs example"
                                    sx={{ borderRight: 1, borderColor: 'divider' }}
                                >
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}><i className="fa-brands fa-rocketchat me-3"></i>Interface </Box>} {...a11yProps(0)} />
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}><i className="fa-solid fa-lock me-3"></i>Security </Box>} {...a11yProps(1)} />
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}><i className="fa-solid fa-chalkboard-user me-3"></i>Model </Box>} {...a11yProps(2)} />
                                    {/* <Tab label={<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}><i className="fa-solid fa-envelope me-3"></i>Leads & Notification </Box>} {...a11yProps(3)} /> */}
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}><i className="fa-solid fa-newspaper me-3"></i>Information </Box>} {...a11yProps(3)} />
                                    <Tab label={<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}><i className="fa-solid fa-language me-3"></i>Language </Box>} {...a11yProps(4)} />
                                </Tabs>
                            </Box>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9'>
                    <div className='custom-padding'>
                        <TabPanel value={value} index={0} sx={{ padding: '0px' }}>
                            <div className='containers p-0'>
                                <div className='chat-container m-0'>
                                    <h4 className='mx-4 my-2'><strong>Chat Interface</strong></h4>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 ps-4">
                                        {/* <p className='m-3'>Filters</p> */}
                                        <h6 className='m-3'><strong>Filters</strong></h6>
                                        <div className='mx-2'>
                                            <form className='setting-form ms-2'>
                                                <div className="form-group interface">
                                                    <label htmlFor="display_name">Display Name</label>
                                                    <input type="text" className="form-control"
                                                        name="display_name"
                                                        id="display_name"
                                                        onChange={handleAppearanceChange}
                                                        defaultValue={appearanceData?.display_name}
                                                        minLength={1}
                                                        maxLength={100}
                                                        placeholder="John" />
                                                    <small className="form-text text-muted">Write the header Name upto 100 chars.</small>
                                                </div>
                                                <div className="form-group interface">
                                                    <label htmlFor="initial_message">Initial Message</label>
                                                    <input type="text" className="form-control"
                                                        name="initial_message"
                                                        id="initial_message"
                                                        onChange={handleAppearanceChange}
                                                        defaultValue={appearanceData?.initial_message}
                                                        minLength={1}
                                                        placeholder="Hi! What can I help with you ?"
                                                    />
                                                    <small className="form-text text-muted">Enter each message in a new line.</small>
                                                </div>
                                                <div className="form-group interface">
                                                    <label htmlFor="footer_text">Footer</label>
                                                    <input type="text" className="form-control"
                                                        name="footer_text"
                                                        id="footer_text"
                                                        onChange={handleAppearanceChange}
                                                        defaultValue={appearanceData?.footer_text}
                                                        minLength={1}
                                                        maxLength={100}
                                                        placeholder="Powered By Nimibot AI"
                                                        readOnly={Team.plan?.subscription_type?.includes('gold') || Team.plan?.subscription_type?.includes('silver') || Team.plan?.subscription_type?.includes('enterprise_subscription')  ? false : true}
                                                    />
                                                    <div style={{ textAlign: 'end' }}><small id="text" className="form-text text-muted">0/100 character</small></div>
                                                    <small id="text" className="form-text text-muted"><strong>You can use this to add a desclaimer or a link to your pravicy policy.</strong></small>
                                                </div>
                                                <div className="form-group interface row">
                                                    <div className='col-6'>
                                                        <label htmlFor="top_bar_background">Theme Background</label>
                                                        <input type="color" className="form-control form-control-color"
                                                            id="myColor"
                                                            name="top_bar_background"
                                                            onChange={handleAppearanceChange}
                                                            value={appearanceData?.top_bar_background}
                                                            title="Choose a color" />
                                                    </div>
                                                    <div className='col-6'>
                                                        <label htmlFor="top_bar_textcolor">Theme Text</label>
                                                        <input type="color" className="form-control form-control-color"
                                                            id="myColor"
                                                            name="top_bar_textcolor"
                                                            onChange={handleAppearanceChange}
                                                            value={appearanceData?.top_bar_textcolor}
                                                            title="Choose a color" />
                                                    </div>
                                                    <div className='col-6'>
                                                        <label htmlFor="bot_message_background">Bot Message Background</label>
                                                        <input type="color" className="form-control form-control-color"
                                                            id="myColor"
                                                            name="bot_message_background"
                                                            onChange={handleAppearanceChange}
                                                            value={appearanceData?.bot_message_background}
                                                            title="Choose a color" />
                                                    </div>
                                                    <div className='col-6'>
                                                        <label htmlFor="bot_message_color">Bot Message Color</label>
                                                        <input type="color" className="form-control form-control-color"
                                                            id="myColor"
                                                            name="bot_message_color"
                                                            onChange={handleAppearanceChange}
                                                            value={appearanceData?.bot_message_color}
                                                            title="Choose a color" />
                                                    </div>
                                                    <div className='col-6'>
                                                        <label htmlFor="user_message_background">User Message Background</label>
                                                        <input type="color" className="form-control form-control-color"
                                                            id="myColor"
                                                            name="user_message_background"
                                                            onChange={handleAppearanceChange}
                                                            value={appearanceData?.user_message_background}
                                                            title="Choose a color" />
                                                    </div>
                                                    <div className='col-6'>
                                                        <label htmlFor="user_message_color">User Message Color</label>
                                                        <input type="color" className="form-control form-control-color"
                                                            id="myColor"
                                                            name="user_message_color"
                                                            onChange={handleAppearanceChange}
                                                            value={appearanceData?.user_message_color}
                                                            title="Choose a color" />
                                                    </div>
                                                    <div className='col-6'>
                                                        <label htmlFor="footer_background_color">Footer Background</label>
                                                        <input type="color" className="form-control form-control-color"
                                                            id="myColor"
                                                            name="footer_background_color"
                                                            onChange={handleAppearanceChange}
                                                            value={appearanceData?.footer_background_color}
                                                            title="Choose a color" />
                                                    </div>
                                                    <div className='col-6'>
                                                        <label htmlFor="footer_text_color">Footer Text</label>
                                                        <input type="color" className="form-control form-control-color"
                                                            id="myColor"
                                                            name="footer_text_color"
                                                            onChange={handleAppearanceChange}
                                                            value={appearanceData?.footer_text_color}
                                                            title="Choose a color" />
                                                    </div>
                                                    <div className='col-6'>
                                                        <label htmlFor="chatbot_background_color">Chat Background</label>
                                                        <input type="color" className="form-control form-control-color"
                                                            id="myColor"
                                                            name="chatbot_background_color"
                                                            onChange={handleAppearanceChange}
                                                            value={appearanceData?.chatbot_background_color}
                                                            title="Choose a color" />
                                                    </div>
                                                </div>
                                                <div className='setuserpic'>
                                                    <img
                                                        src={appearanceData.chatbot_background_image}
                                                        ref={chatbotBgImageRef}
                                                        alt="..." className="img-thumbnail" style={{  height: "80px",width: "80px", borderRadius: "50%" }} />
                                                    <div className='profile ms-4'>
                                                        <p>Background Image</p>
                                                        <input type="file"
                                                            name="chatbot_background_image"
                                                            id="chatbot_background_image"
                                                            accept="image/*"
                                                            onChange={handleFileChange} /><br />
                                                        <small id="text" className="form-text text-muted">Support JPG, PNG and SVG files upto 1MB</small>
                                                    </div>
                                                </div>
                                                <div className="form-group interface">
                                                    <small id="text" className="form-text text-muted"><strong>**If the changes here don't show up immediately on your website try clearing your browser cache or use incognito.(New users will see the changes immediately)**</strong></small>
                                                </div>
                                                <div className='setuserpic'>
                                                    <img
                                                        src={appearanceData.chatbot_image}
                                                        ref={chatbotImageRef}
                                                        alt="..." className="img-thumbnail" style={{ height: "80px",width: "80px", borderRadius: "50%" }} />
                                                    <div className='profile ms-4'>
                                                        <p>Chat Icon</p>
                                                        <input type="file"
                                                            name="chatbot_image"
                                                            id="chatbot_image"
                                                            accept="image/*"
                                                            onChange={handleFileChange} /><br />
                                                        <small id="text" className="form-text text-muted">Support JPG, PNG and SVG files upto 1MB</small>
                                                    </div>
                                                </div>
                                                <div className='setuserpic'>
                                                    <img
                                                        src={appearanceData.chatbot_logo}
                                                        ref={chatbotLogoRef}
                                                        alt="..." className="img-thumbnail" style={{  height: "80px",width: "80px", borderRadius: "50%" }} />
                                                    <div className='profile ms-4'>
                                                        <p>Profile Picture</p>
                                                        <input type="file"
                                                            name="chatbot_logo"
                                                            id="chatbot_logo"
                                                            accept="image/*"
                                                            onChange={handleFileChange} /><br />
                                                        <small id="text" className="form-text text-muted">Support JPG, PNG and SVG files upto 1MB</small>
                                                    </div>
                                                </div>
                                                <div className="form-group interface">
                                                    <label htmlFor="exampleInputtext">Widget Width</label>
                                                    <select className="form-select" value={appearanceData?.widget_width} name='widget_width' onChange={handleAppearanceChange}>
                                                        <option value="400">Mini</option>
                                                        <option value="450">Medium</option>
                                                        <option value="500">XL</option>
                                                        <option value="550">XXL</option>
                                                    </select>
                                                </div>
                                                <div className="form-group interface">
                                                    <label htmlFor="exampleInputtext">Widget Height</label>
                                                    <select className="form-select" value={appearanceData?.widget_height} name='widget_height' onChange={handleAppearanceChange}>
                                                        <option value="400">Mini</option>
                                                        <option value="450">Medium</option>
                                                        <option value="500">XL</option>
                                                        <option value="550">XXL</option>
                                                    </select>
                                                </div>
                                                <div className="form-group interface">
                                                    <label htmlFor="exampleInputtext">Align Bubble Button</label>
                                                    <select className="form-select" value={appearanceData?.widget_position} name='widget_position' onChange={handleAppearanceChange}>
                                                        <option value="right">Right</option>
                                                        <option value="left">Left</option>
                                                    </select>
                                                </div>
                                                <div style={{ textAlign: "start" }}>
                                                    <button type="button" onClick={handleAppearanceSubmit} className="btn btn-primary m-3">Save All Changes</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-sm- col-md- col-lg- col-xl-5 p-4">
                                        <div className='previewbox'>
                                            <h4>Preview</h4>
                                            <div className='prexvbox'>
                                                <div className='chat-container preview' style={{ justifyContent: "space-between", backgroundColor: appearanceData?.top_bar_background }}>
                                                    <div style={{ color: appearanceData?.top_bar_textcolor }} className='prev-head'>
                                                        <img src={appearanceData?.chatbot_logo} ref={chatbotLogoViewRef} style={{ width: '25px' }} />
                                                        {appearanceData?.display_name}</div>
                                                    <div>
                                                        <button type="button" className="btn"><i style={{ fontSize: "16px", color: appearanceData?.top_bar_textcolor }} className="fas fa-sync p-1"></i></button>
                                                        <button type="button" className="btn"><i style={{ fontSize: "20px", color: appearanceData?.top_bar_textcolor }} className="fa fa-times p-1"></i></button>
                                                    </div>
                                                </div>
                                                <div className='chatbot__box'>
                                                    <div className="chat-content" ref={chatbotBgViewRef}
                                                        style={{
                                                            backgroundColor: appearanceData?.chatbot_background_color,
                                                            backgroundImage: `url(${appearanceData.chatbot_background_image})`,
                                                            backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition: "center"
                                                        }}>
                                                        <div className="chat-message bot-message">
                                                            <div className="user-text-message">
                                                                <div className="bot-message-container">
                                                                    <img src={appearanceData?.chatbot_image} ref={chatbotImageViewRef} style={{ width: '25px' }} />
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <p className="bot-text" style={{ color: appearanceData?.bot_message_color, backgroundColor: appearanceData?.bot_message_background }}>{appearanceData?.initial_message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat-message user-message">
                                                            <div className="client-text-message">
                                                                <p className="user-text" style={{ color: appearanceData?.user_message_color, backgroundColor: appearanceData?.user_message_background }}>Hi There</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='interfacemsgbox prevmsgbox' style={{ backgroundColor: appearanceData?.top_bar_background }}>
                                                    <input type="text" className="form-control me-1" id="exampleInputtext" aria-describedby="text" placeholder="Message..." style={{ borderRadius: "10px", border:"1px solid #dee2e6 "}} />
                                                    <button className='Prevsend-button' style={{ backgroundColor: appearanceData?.top_bar_background }}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.74928 11.2501L17.4993 2.50014M8.85559 11.5235L11.0457 17.1552C11.2386 17.6513 11.3351 17.8994 11.4741 17.9718C11.5946 18.0346 11.7381 18.0347 11.8587 17.972C11.9978 17.8998 12.0946 17.6518 12.2881 17.1559L17.78 3.08281C17.9547 2.63516 18.0421 2.41133 17.9943 2.26831C17.9528 2.1441 17.8553 2.04663 17.7311 2.00514C17.5881 1.95736 17.3643 2.0447 16.9166 2.21939L2.84349 7.71134C2.34759 7.90486 2.09965 8.00163 2.02739 8.14071C1.96475 8.26129 1.96483 8.40483 2.02761 8.52533C2.10004 8.66433 2.3481 8.7608 2.84422 8.95373L8.47589 11.1438C8.5766 11.183 8.62695 11.2026 8.66935 11.2328C8.70693 11.2596 8.7398 11.2925 8.7666 11.3301C8.79685 11.3725 8.81643 11.4228 8.85559 11.5235Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg></button>
                                                </div>
                                                <div className='interfacemsgbox d-flex justify-content-center' style={{ backgroundColor: appearanceData?.footer_background_color, borderTop: "1px solid #fff" }}>
                                                    <div style={{ color: appearanceData?.footer_text_color, fontSize: "14px" }} >
                                                        {Team.plan?.subscription_type?.includes('gold') || Team.plan?.subscription_type?.includes('silver') || Team.plan?.subscription_type?.includes('enterprise_subscription') ? false : (<img src='/assets/Logo3.png' ref={chatbotImageViewRef} style={{ width: '20px' }} />)}

                                                        &nbsp;{appearanceData?.footer_text}</div>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-4' style={{ justifyContent: appearanceData?.widget_position }}>
                                                <div>
                                                    <img src={BaseApiUrl + 'media/default_image/1.png'} style={{ width: '50px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className='containers p-0'>
                                <div className='chat-container m-0'>
                                    <h4 className='mx-4 my-2'><strong>Security</strong></h4>
                                </div>
                                <div className='row'>
                                    <div className="col-12 securityspace">
                                        <h6 className='m-3 ps-2'><strong>Filters</strong></h6>
                                        <div>
                                            <form className='setting-form px-4' onSubmit={handleSecurityFormSubmit} >
                                                <div className="form-group mb-4">
                                                    <label htmlFor="exampleInputtext">Visibility</label>
                                                    <select className="form-select" name='visibility' onChange={handleSecurityChange} value={botData?.visibility}>
                                                        <option value="public">Public</option>
                                                        <option value="private">Private</option>
                                                    </select>
                                                    <small id="text" className="form-text text-muted">
                                                        Control who can view and interact with the chatbot. This setting allows you to make the bot public for all users, private for restricted users, or customizable based on specific conditions
                                                    </small>
                                                </div>
                                                <div className="form-group mb-5">
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <label htmlFor="exampleInputtext">Rate Limiting</label>
                                                        <button type="button" className="btn btn-outline-dark my-2">Reset</button>
                                                    </div>
                                                    <small id="text" className="form-text text-muted">
                                                        Limit the number of messages a user can send within a specified timeframe. This helps to prevent spamming and ensures a smooth conversation flow for all users.
                                                    </small>
                                                    <div style={{ display: "flex" }}>
                                                        <small>Limit to only</small>
                                                        <input className='mx-2' type="number" id="tentacles" name="tentacles" min="10" max="500" />
                                                        <small>messages every</small>
                                                        <input className='mx-2' type="number" id="tentacles" name="tentacles" min="10" max="500" />
                                                        <small>second</small>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-5">
                                                    <label htmlFor="exampleInputtext">Show this message to show when limit is hit</label>
                                                    <input type="text" className="form-control" id="exampleInputtext" aria-describedby="text" placeholder="Too many messages in a row" />
                                                </div>

                                                <div style={{ textAlign: "Start" }}>
                                                    <button type="submit" className="btn btn-primary m-3">Save All Changes</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className='containers p-0 mb-4' style={{ minHeight: "15rem" }}>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Training</strong></h4>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className='m-4'>
                                        <p>Last Trained</p>
                                        <h4 style={{ fontWeight: "600" }}>{chatbotData?.last_train}</h4>
                                    </div>
                                    {/* <div style={{ textAlign: "end" }}>
                                        <button type="button" className="btn btn-primary m-3">View Source</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className='containers p-0 mb-4' style={{ minHeight: "10rem" }}>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Chatbot Profile</strong></h4>
                                </div>
                                <div className='row botprofileContainer'>
                                    <div className='col-sm-3 col-md-3 col-lg-2 col-xl-2' style={{ textAlign: "center" }}>
                                        <Avatar name="+" round={true} size="75" onClick={handleAddModel} style={{ cursor: 'pointer' }} />
                                    </div>
                                    {modelList.map((item, index) => (
                                        <div key={index} className={`col-sm-3 col-md-3 col-lg-2 col-xl-2 pt-2 ${item.is_active ? 'avtarbox' : ''}`} style={{ textAlign: "center" }}>
                                            <div className='modalprofile' style={{ display: "grid", justifyContent: "center", cursor: 'pointer' }}>
                                                <i className="fa-solid fa-trash-can" onClick={(e) => handleDeleteModelProfile(item.id)}></i>
                                                <Avatar name={item.name} round={true} size="75" onClick={(e) => handleAddModel(e, item)} />
                                            </div>
                                            <p className='text-capitalize'>{item.name}</p>
                                            {item.name == modelBox.name && !item.is_active ? (
                                                <button className='btn btn-primary' onClick={(e) => handleActiveModel(e, item)}>Activate</button>
                                            ) : ""}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='containers p-0'>
                                <form onSubmit={handleAddModelFromSubmit}>
                                    <div className='chat-container'>
                                        <h4 className='mx-4 my-2'><strong>Expert Mode</strong></h4>
                                    </div>
                                    <div className='col-12 px-4'>
                                        <div className="form-group mb-5">
                                            <label htmlFor="name">Profile name of the chatbot</label>
                                            <input type="text" className="form-control" id="name" value={modelBox.name} placeholder="Custom name" onChange={handleModelProfileChange} name='name' required />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="instruction">Instructions for Chatbot, on how to respond</label>
                                            <textarea className="form-control" placeholder='Instructions' style={{ borderRadius: "10px" }} rows="3" id="instruction" value={modelBox.instruction} onChange={handleModelProfileChange} name='instruction' required></textarea>
                                            <small id="text" className="form-text text-muted">Here you can give response that the chat bot needs to provide in example limit to only 15 words or provide info in bullet points, etc.</small>
                                        </div>
                                        <div className='gpt mt-5' style={{ display: "flex", alignItems: "center" }}>
                                            <p>gpt-3.5-turbo is now available</p>
                                        </div>
                                        <label htmlFor="model">Select model</label>
                                        <select className="form-control" id="model" value={modelBox.model} onChange={handleModelProfileChange} name='model' required>
                                            <option value="" disabled>Select</option>
                                            <option value="gpt-3.5-turbo">GPT-3.5-Turbo</option>
                                            <option value="gpt-4">GPT-4</option>
                                        </select>
                                        <div >
                                            <h4>Chatbot response</h4>
                                            <span>0 signifies accurate to the points</span>
                                        </div>
                                        <div className='mb-3'>
                                            <Slider
                                                name='temperature'
                                                aria-label="Small steps"
                                                value={modelBox.temperature}
                                                getAriaValueText={valuetext}
                                                step={0.1}
                                                min={0.0}
                                                max={1.0}
                                                valueLabelDisplay="auto"
                                                onChange={handleModelProfileChange}
                                                required
                                            />
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <span>Reserved</span>
                                                <span>Creative</span>
                                            </div>
                                        </div>
                                        {!modelBox.id ? (
                                            <div style={{ textAlign: "Start" }}>
                                                <button type="submit" className="btn btn-primary my-3">Add Model Profile</button>
                                            </div>
                                        ) : ""}
                                    </div>
                                </form>
                            </div>
                        </TabPanel>
                        {/* <TabPanel value={value} index={3}>
                            <div className='containers p-0 mb-5'>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Setup Lead Form</strong></h4>
                                </div>
                                <div className='col-12 px-4'>
                                    <div className="form-group">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <label htmlFor="exampleInputEmail1"><h6 className='m-0'>Message for Customer</h6></label>
                                        </div>
                                        <div className='notification'>
                                            <input type="email" className="form-control"
                                            ref={loadFormHeadingRef}
                                            defaultValue={loadFormHeading}
                                            onChange={(event)=>{setLoadFormHeading(event.target.value)}}
                                            placeholder="Let us know how to Contact you" />
                                            <label className="switch">
                                                <input type="checkbox" defaultChecked={showLoadForm} onChange={(event)=>{setShowLoadForm(event.target.checked)}} />
                                                <span className="slider round"></span>
                                            </label>
                                            <button type="button" className="btn btn-primary mx-2" onClick={resetMainText}>Reset</button>
                                            <button type="button" className="btn btn-primary mx-2" onClick={handleShow}>New Field</button>
                                        </div>
                                    </div>
                                    {loadForms.map((data, index) => (
                                        <div key={index} className='receivemail'>
                                            <label htmlFor="exampleInputEmail1"><h6 className='m-0'>{data.name}</h6></label>
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveField(data.name)}
                                                className="btn">
                                                <i className="fa-solid fa-trash-can mx-2" style={{color: 'red'}}></i>
                                            </button>
                                        </div>
                                    ))}
                                    <div style={{ textAlign: "start" }}>
                                        <button type="button" className="btn btn-primary m-3" onClick={handleListSubmit}>Save All Changes</button>
                                    </div>
                                </div>
                            </div>
                            <div className='containers p-0 notifybox'>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Notifications</strong></h4>
                                </div>
                                <div className='col-12 px-4'>
                                    <div className="form-group">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <label htmlFor="exampleInputEmail1"><h4>Receive email with daily leads</h4></label>
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className='notification'>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                                            <button type="button" className="btn btn-outline-dark">Add Email</button>
                                        </div>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: "40%" }} placeholder="name@example.com" />                                    </div>

                                    <div className='receivemail'>
                                        <label htmlFor="exampleInputEmail1"><h4 className='m-0'>Receive email with daily conversations</h4></label>
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    <div style={{ textAlign: "start" }}>
                                        <button type="button" className="btn btn-primary m-3">Save All Changes</button>
                                    </div>
                                </div>
                            </div>
                        </TabPanel> */}
                        <TabPanel value={value} index={3}>
                            <div className='containers p-0'>
                                <div className='chat-container'>
                                    <h4 className='mx-4 my-2'><strong>Information</strong></h4>
                                </div>
                                <div className='col-12 px-4 my-3'>
                                    <div className="form-group">
                                        <div>
                                            <label htmlFor="exampleInputEmail1"><h6 className='m-0'>Chatbot ID</h6></label>
                                            <h4 style={{ fontWeight: "600" }}>{chatbotData?.chatbot_id}</h4>
                                        </div>
                                        <div className='mt-5'>
                                            <label htmlFor="exampleInputEmail1"><h6 className='m-0'>Number of Characters</h6></label>
                                            <h4 style={{ fontWeight: "600" }}>{chatbotData?.total_char_sum}</h4>
                                        </div>
                                        <div className='mt-5'>
                                            <label htmlFor="exampleInputEmail1"><h6 className='m-0'>Name</h6></label>
                                            <input type="text" className="form-control" style={{ borderRadius: "10px" }} defaultValue={chatbotData?.chatbot_name} name='chatbot_name' onChange={handleSecurityChange} placeholder="Nimibot AI Support" />
                                        </div>
                                    </div>
                                    <div style={{ textAlign: "start" }}>
                                        <button type="button" onClick={handleSecurityFormSubmit} className="btn btn-primary m-3">Save All Changes</button>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <div className='containers p-0'>
                                <div className='chat-container m-0'>
                                    <h4 className='mx-4 my-2'><strong>Language</strong></h4>
                                </div>
                                <div className='row'>
                                    <div className="col-12 ">
                                        {/* <p className='m-3'>Main Chatbot language</p> */}
                                        <div>
                                            <form className='setting-form my-3 px-4' onSubmit={handleSecurityFormSubmit} >
                                                <div className="form-group mb-4">
                                                    <label htmlFor="exampleInputtext">Main Chatbot language</label>
                                                    <select className="form-select" aria-label="Default select example" value={botData?.language} name='language' onChange={handleSecurityChange} >
                                                        <option value="en">English</option>
                                                        <option value="he">Hebrew</option>
                                                    </select>
                                                    <small id="text" className="form-text text-muted">
                                                        Switch languages seamlessly with just a click! Choose your preferred language to continue the conversation effortlessly.
                                                    </small>
                                                </div>

                                                <div className="form-group mb-4">
                                                    <label htmlFor="exampleInputtext">Chatbot Text Direction</label>
                                                    <select className="form-select" aria-label="Default select example" value={botData?.language} name='language' onChange={handleSecurityChange}>
                                                        <option value="en">Left to Right</option>
                                                        <option value="he">Right to Left</option>
                                                    </select>
                                                    <small id="text" className="form-text text-muted">
                                                        Adjust text direction easily! Switch between Left-to-Right (LTR) and Right-to-Left (RTL) for a smoother reading experience in your language.
                                                    </small>
                                                </div>

                                                <div style={{ textAlign: "Start" }}>
                                                    <button type="submit" className="btn btn-primary m-3">Save All Changes</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </div>
                {/* <div className='col-3'>
                    <div className='outer-space'>
                        <div className='containertwo p-2'>
                            <h5>Sources</h5>
                            <div className=''>
                                <span><strong>Total detected character</strong></span><br />
                                <span>0/400,00 limit</span>
                            </div>
                            <button type="button" className="btn btn-primary" style={{ color: '#fff', margin: '20px 75px' }}>Retrain Chatbot</button>
                        </div>
                    </div>
                </div> */}

                {/* <TabPanel value={value} index={0}>
                    
                </TabPanel>
                <TabPanel value={value} index={1}>

                    <div className='mt-5'>
                        <table className="table-bordr">
                            <thead>
                                <tr>
                                    <th><input className="form-check-input me-2" type="checkbox" value="" id="flexCheckChecked" checked />Name</th>
                                    <th>Upload By</th>
                                    <th>Upload Date</th>
                                    <th>File Size</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><input className="form-check-input me-2" type="checkbox" value="" id="flexCheckChecked" checked />Makiting details DashBiard</td>
                                    <td>Sifat</td>
                                    <td>05/12/24</td>
                                    <td>6 mb</td>
                                    <td><span style={{ backgroundColor: '#82d982', borderRadius: "20px", padding: "4px" }}>Success</span></td>
                                    <td><img src="/icons/deletebtn.svg" style={{ width: '10%' }} className="" alt="logo" /></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>

                </TabPanel>
                <TabPanel value={value} index={3}>

                    <div className='mt-5'>
                        <table className="table-bordr">
                            <thead>
                                <tr>
                                    <th><input className="form-check-input me-2" type="checkbox" value="" id="flexCheckChecked" checked />Name</th>
                                    <th>Upload By</th>
                                    <th>Upload Date</th>
                                    <th>File Size</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><input className="form-check-input me-2" type="checkbox" value="" id="flexCheckChecked" checked />Makiting details DashBiard</td>
                                    <td>Sifat</td>
                                    <td>05/12/24</td>
                                    <td>6 mb</td>
                                    <td><span style={{ backgroundColor: '#82d982', borderRadius: "20px", padding: "4px" }}>Success</span></td>
                                    <td><img src="/icons/deletebtn.svg" style={{ width: '10%' }} className="" alt="logo" /></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={4}>

                </TabPanel> */}

            </div>


            {/* Modal */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Field</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleAddSubmit}>
                        <div className="form-group mb-1">
                            <label htmlFor="label">Label</label>
                            <input
                                type="text"
                                required
                                name="name"
                                onChange={handleLoadFormChange}
                                inputMode="text"
                                className="form-control"
                                id="label"
                                placeholder="Label here..."
                                maxLength={100}
                            />
                        </div>
                        <div className="form-group mb-1">
                            <label htmlFor="size">Size</label>
                            <input
                                type="number"
                                required
                                name="size"
                                onChange={handleLoadFormChange}
                                inputMode="numeric"
                                className="form-control"
                                id="size"
                                placeholder="Size Here..."
                            />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="type">Select Page</label>
                            <select id="type" className="form-select form-select-md mb-3" onChange={handleLoadFormChange} name="type" required>
                                <option value="text">Text</option>
                                <option value="email">Email</option>
                                <option value="number">Number</option>
                            </select>
                        </div>
                        <button type="submit" className="btn btn-primary me-4"> Add Field</button>
                        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Settings;
