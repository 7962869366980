import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import OpenHeader from '../Components/OpenHeader';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PlanAdd from './PlanAdd-On';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Compare = () => {
    const [value, setValue] = useState(0);

    const navigate = useNavigate();
    const NewGenerate = () => {
        // navigate("/dashboard");
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <>
            {/* Header */}
            <OpenHeader />
            {/* Header */}
            <div className="compare">
                <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 my-2'>
                    <h3>Compare all our Plans</h3>
                    <div className='mainswitch'>
                        <Box sx={{ border: '1px ridge', backgroundColor: '#ffffff', padding: '8px', borderRadius: '50px' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
                                <Tab label="Monthly" {...a11yProps(0)} />
                                <Tab label="Yearly" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                    </div></div>
                    <CustomTabPanel value={value} index={0}>
                    <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 my-2 text-center'>
                        <h3>Free</h3>
                        <h6>$0 Forever</h6>
                        <button type="button" className="btn btn-outline-secondary py-2 px-5">Get Started</button>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 my-2 text-center'>
                        <h3>Bronze</h3>
                        <h6>$19 / Month</h6>
                        <button type="button" className="btn btn-outline-secondary py-2 px-5">Subscribe</button>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 my-2 text-center'>
                        <h3>Silver</h3>
                        <h6>$99 / Month</h6>
                        <button type="button" className="btn btn-outline-secondary py-2 px-5">Subscribe</button>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 my-2 text-center'>
                        <h3>Gold</h3>
                        <h6>$499 / Month</h6>
                        <button type="button" className="btn btn-outline-secondary py-2 px-5">Subscribe</button>
                    </div>
                </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                    <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 my-2 text-center'>
                        <h3>Free</h3>
                        <h6>$0 Forever</h6>
                        <button type="button" className="btn btn-outline-secondary py-2 px-5">Get Started</button>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 my-2 text-center'>
                        <h3>Bronze</h3>
                        <h6>$193.8 / Year</h6>
                        <button type="button" className="btn btn-outline-secondary py-2 px-5">Subscribe</button>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 my-2 text-center'>
                        <h3>Silver</h3>
                        <h6>$1009.9 / Year</h6>
                        <button type="button" className="btn btn-outline-secondary py-2 px-5">Subscribe</button>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 my-2 text-center'>
                        <h3>Gold</h3>
                        <h6>$5090 / Year</h6>
                        <button type="button" className="btn btn-outline-secondary py-2 px-5">Subscribe</button>
                    </div>
                </div>
                    </CustomTabPanel>
                

                <div>
                    <CustomTabPanel value={value} index={0}>
                        <table className="table comparetable">
                            <thead>
                                <tr>
                                    <th scope="col">Configuration</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Chatbots</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>5</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>Team members</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>5</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>Embedding Characters</td>
                                    <td>400,000</td>
                                    <td>1,000,000</td>
                                    <td>5,000,000</td>
                                    <td>10,000,000</td>
                                </tr>
                                <tr>
                                    <td>Message credits (monthly)</td>
                                    <td>20</td>
                                    <td>200</td>
                                    <td>1,000</td>
                                    <td>5,000</td>
                                </tr>
                                <tr>
                                    <td>Embed on unlimited websites</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table comparetable">
                            <thead>
                                <tr>
                                    <th scope="col">Training</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Links to train on</td>
                                    <td>4</td>
                                    <td>15</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table comparetable">
                            <thead>
                                <tr>
                                    <th scope="col">Branding</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Remove "Powered by Nimibot AI"</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Choose your own custom domain</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table comparetable">
                            <thead>
                                <tr>
                                    <th scope="col">API-Integrations & Support</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>API access</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Support</td>
                                    <td>Basic</td>
                                    <td>Standard</td>
                                    <td>Premium</td>
                                    <td>Premium</td>
                                </tr>
                            </tbody>
                        </table>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                    <table className="table comparetable">
                            <thead>
                                <tr>
                                    <th scope="col">Configuration</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Chatbots</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>5</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>Team members</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>5</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>Embedding Characters</td>
                                    <td>400,000</td>
                                    <td>1,000,000</td>
                                    <td>5,000,000</td>
                                    <td>10,000,000</td>
                                </tr>
                                <tr>
                                    <td>Message credits (monthly)</td>
                                    <td>20</td>
                                    <td>200</td>
                                    <td>1,000</td>
                                    <td>5,000</td>
                                </tr>
                                <tr>
                                    <td>Embed on unlimited websites</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table comparetable">
                            <thead>
                                <tr>
                                    <th scope="col">Training</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Links to train on</td>
                                    <td>4</td>
                                    <td>15</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table comparetable">
                            <thead>
                                <tr>
                                    <th scope="col">Branding</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Remove "Powered by Nimibot AI"</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Choose your own custom domain</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table comparetable">
                            <thead>
                                <tr>
                                    <th scope="col">API-Integrations & Support</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>API access</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Support</td>
                                    <td>Basic</td>
                                    <td>Standard</td>
                                    <td>Premium</td>
                                    <td>Premium</td>
                                </tr>
                            </tbody>
                        </table>
                    </CustomTabPanel>
                    <PlanAdd/>
                </div>
            </div>
        </>
    );
};

export default Compare;