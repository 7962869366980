import '../../Admin/style.css';
import React, { useContext, useEffect, useState } from 'react';
import UserApiClient from '../../ApiClients';
import { UserContext } from '../../TabContextProvider';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useCountries } from 'use-react-countries'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

function Payment() {

    const { countries } = useCountries()

    const { User } = useContext(UserContext);

    const [email, setEmail] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('');
    const [address, setAddress] = useState('');
    const [errors, setErrors] = useState({});
    const [phone, setPhone] = useState("");
    const [planConfig, setPlanConfig] = useState({});
    const navigate = useNavigate();
    let isFetching = false;


    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const plan = urlParams.get('plan');
    const team_id = urlParams.get('team');
    const uEmail = urlParams.get('email');

    if (!plan && plan != 'undefined' || !team_id && team_id != 'undefined' || !uEmail && uEmail != 'undefined') navigate('/');

    const validateCardNumber = (number) => {
        const regex = /^\d{16}$/;
        return regex.test(number.replaceAll(' ', ''));
    };

    const validateExpiryDate = (date) => {
        const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
        if (!regex.test(date)) {
            return false;
        }
        const [month, year] = date.split('/').map(Number);
        const currentYear = new Date().getFullYear() % 100; // Get last two digits of current year
        const currentMonth = new Date().getMonth() + 1; // Months are zero-indexed
        if (year < currentYear) {
            return false;
        }
        if (year === currentYear && month < currentMonth) {
            return false;
        }
        return true;
    };

    const validateCvv = (cvv) => {
        const regex = /^\d{3,4}$/; // 3 or 4 digits
        return regex.test(cvv);
    };

    const validatePhoneNumber = (value) => {
        const regex = /^(?:\+?(\d{1,3}))?[\s-]?(\d{3})[\s-]?(\d{3})[\s-]?(\d{3,4})$/;
        return regex.test(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let validationErrors = {};

        if (!validateCardNumber(cardNumber)) {
            validationErrors.cardNumber = 'Invalid card number';
        }
        if (!validateExpiryDate(expiryDate)) {
            validationErrors.expiryDate = 'Invalid expiry date';
        }
        if (!validateCvv(cvv)) {
            validationErrors.cvv = 'Invalid CVV';
        }
        if (!validatePhoneNumber(phoneNumber)) {
            validationErrors.phoneNumber = 'Invalid Phone Number';
        }
        if (!email) {
            validationErrors.email = 'Email is required';
        }
        if (!name) {
            validationErrors.name = 'Name is required';
        }
        if (!country) {
            validationErrors.country = 'Country is required';
        }
        if (!address) {
            validationErrors.address = 'Address is required';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const data = {
            email: email,
            cc_number: cardNumber.replaceAll(' ', ''),
            cc_validity: expiryDate,
            cc_cvv: cvv,
            cc_holder_name: name,
            subscription_type: plan,
            phone_number: phoneNumber,
            country: country || event.target.country.value,
            address: address,
        }
        // Handle successful submission

        await UserApiClient.post(`api/recurring/${team_id}/`, data, true).then(async res => {
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showCancelButton: false,
                    showCloseButton: false,
                    showConfirmButton: false,
                    timer: 3000
                })
            }
            setTimeout(() => { navigate('/settings?tab=2') }, 2000);
        })
    };

    async function fetchData() {
        isFetching = true
        await UserApiClient.get(`api/recurring/config/${plan}/`, {}, false).then(async res => {
            if (res.success) {
                setPlanConfig(res.data);
                isFetching = false
            } else {
                navigate('/')
            }
        })
    }

    useEffect(() => {
        if (plan && plan != 'undefined') {
            fetchData();
        }
    }, [plan])

    useEffect(() => {
        if (uEmail && uEmail != 'undefined') {
            setEmail(uEmail);
        }
    }, [uEmail])

    return (
        <>
            <div className='backarrow'></div>
            <div className="containerpay mt-5">
                <div className="row m-0">
                    <div className="col-sm-12 col-md-6  bg-purple text-white p-5">
                        <div className='d-flex align-items-center gap-2 mb-4'>
                            <i className="fa-solid fa-circle-arrow-left" onClick={() => navigate('/settings?tab=2')} style={{ fontSize: '24px', cursor: "pointer" }}></i>
                            <img src="/assets/Logo3.png" className="logo" onClick={() => navigate('/settings?tab=2')} alt="logo" style={{ width: "10%", cursor: "pointer" }} />
                        </div>
                        <h4>Subscribe to {planConfig.plan}</h4>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h1 className="display-4">${planConfig.amount}</h1>
                                <p className='mt-4'>/per {planConfig.no_of_days == 30 ? 'month' : 'year'}</p>
                            </div>
                        </div>
                        <hr className="bg-light" />
                        <div className='d-flex justify-content-between mx-2'>
                            <p>Subtotal:</p>
                            <p>${planConfig.amount}</p>
                        </div>
                        <hr className="bg-light" />
                        <div className='d-flex justify-content-between mx-2'>
                            <p>Tax:</p>
                            <p>$0.0</p>
                        </div>
                        <hr className="bg-light" />
                        <div className='d-flex justify-content-between mx-2'>
                            <p>Total due today:</p>
                            <p>${planConfig.amount}</p>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 bg-light p-5">
                        <h4>Pay with credit card</h4>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                                <label htmlFor="email">Email Address</label>
                                <input type="email" className="form-control" defaultValue={uEmail} id="email" placeholder="Enter email address" onChange={(e) => setEmail(e.target.value)} required disabled readOnly />
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="cardNumber">Card Information</label>

                                <input
                                    type="text"
                                    className="form-control"
                                    id="cardNumber"
                                    placeholder="1234 1234 1234 1234"
                                    value={cardNumber}
                                    maxLength={19}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                        const formattedValue = value.replace(/(\d{4})(?=\d)/g, '$1 '); // Format with spaces every 4 digits
                                        setCardNumber(formattedValue);
                                    }}
                                    required
                                />
                                {errors.cardNumber && <div className="text-danger">{errors.cardNumber}</div>}
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="MM/YY"
                                            value={expiryDate}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                                const formattedValue = value.replace(/(\d{2})(?=\d)/g, '$1/'); // Add slash after every 2 digits
                                                setExpiryDate(formattedValue);
                                            }}
                                            maxLength={5} // 4 digits + 1 slash
                                            required
                                        />
                                        {errors.expiryDate && <div className="text-danger">{errors.expiryDate}</div>}
                                    </div>
                                    <div className="col-6">
                                        <input type="text" className="form-control" placeholder="CVC" maxLength={3}
                                            value={cvv}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                                setCvv(value); // Set the state only if length is 3 or less
                                            }}
                                            required />
                                        {errors.cvv && <div className="text-danger">{errors.cvv}</div>}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="cardholder">Cardholder Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cardholder"
                                    placeholder="Full name on card"
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            {errors.name && <div className="text-danger">{errors.name}</div>}
                            <div className="form-group mb-3">
                                <label htmlFor="billingAddress">Billing address</label>
                                <select className="form-control mb-2" name='country' onChange={(e) => setCountry(e.target.value)} required >
                                    <option value=''>Select Country</option>
                                    {countries.map(({ name }) => (
                                        <option key={name}>{name}</option>
                                    ))}
                                </select>
                                {errors.country && <div className="text-danger">{errors.country}</div>}
                                <input type="text" className="form-control" placeholder="Address" maxLength={255} onChange={(e) => setAddress(e.target.value)} required />
                                {errors.address && <div className="text-danger">{errors.address}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="phoneNumber">Phone Number</label>
                                <div className="input-group d-flex">
                                    <PhoneInput
                                        country={"us"}
                                        // enableSearch={true}
                                        value={phone}
                                        onChange={(phone) => setPhone(phone)}
                                        placeholder='+1'
                                        className='phoneCode mr-2'
                                    />
                                    <input
                                        type="number"
                                        max={999999999999}
                                        maxLength={10}
                                        min={1000000000}
                                        className="form-control"
                                        id="phoneNumber"
                                        placeholder="01234 56789"
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        required
                                        style={{ borderRadius: "5px" }}
                                    />
                                </div>
                                {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
                            </div>
                            <button type="submit" className="btn btn-primary w-100">
                                Subscribe
                            </button>
                        </form>
                        <p className="text-muted mt-2">
                            By confirming your subscription, you allow Nimibot AI to charge you for
                            future payments in accordance with their terms. You can always cancel your
                            subscription.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Payment;